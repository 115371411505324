import React, { useState, useEffect } from "react";
import { useRouteMatch, Link } from "react-router-dom";

// Import icon
import { IoIosArrowRoundBack } from "react-icons/io";

// Import Styles
import {
  ClientWrapper,
  ActualityTitle,
  ClientImageWrapper,
  ActualityHeader,
  Back,
} from "../../styles/ActualitiesStyles";

// Import Component
import UpdateClientName from "./UpdateClientName";
import UpdateClientLogo from "./UpdateClientLogo";

// Import Firestore
import { fs } from "../../firebase";

const Client = () => {
  const [image, setImage] = useState("");
  const [ActualityName, setClientName] = useState("");
  const [imageName, setImageName] = useState("");

  const match = useRouteMatch("/clients/:client");
  const { client } = match.params;

  useEffect(() => {
    fs.collection("Clients")
      .doc(client)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setImage(doc.data().image);
          setClientName(doc.data().name);
          setImageName(doc.data().imageName);
        }
      });
  }, [client]);

  return (
    <ClientWrapper>
      <ActualityHeader>
        <Back>
          <Link to="/">
            <IoIosArrowRoundBack />
          </Link>
        </Back>
        <ActualityTitle>
          Client : <span>{ActualityName}</span>
        </ActualityTitle>
      </ActualityHeader>
      <div className="series_container">
        <ClientImageWrapper>
          <div>
            <img src={image} alt={image} />
          </div>
          <UpdateClientLogo name={imageName} currentClient={client} />
        </ClientImageWrapper>
        <UpdateClientName currentClient={client} />
      </div>
    </ClientWrapper>
  );
};

export default Client;
