import React, { useState, useEffect } from "react";

// Import Styles
import { ActyalityDesc } from "../../styles/ActualitiesStyles";
import { Submit } from "../../styles/layout";

// Import Firetore
import { fs } from "../../firebase";

const UpdateClientName = ({ currentClient }) => {
  const [name, setName] = useState("");

  const onNameChange = (e) => setName(e.target.value);

  useEffect(() => {
    const serieRef = fs.collection("Clients").doc(currentClient);
    serieRef.get().then((doc) => {
      let { name } = doc.data();
      setName(name);
    });
  }, [currentClient]);

  const onSend = () => {
    if (name) {
      fs.collection("Clients").doc(currentClient).update({
        name: name,
      });
    }
  };

  return (
    <div className="desc_container">
      <ActyalityDesc>
        <label>
          <p>Titre</p>
          <input
            type="text"
            name="title"
            value={name}
            onChange={onNameChange}
          />
        </label>
      </ActyalityDesc>

      <ActyalityDesc className="eventSubmit">
        <Submit onClick={onSend}>Mettre à jour</Submit>
      </ActyalityDesc>
    </div>
  );
};

export default UpdateClientName;
