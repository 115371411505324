import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//Import Components
import NewClient from "./NewClient";
import DeleteClient from "./DeleteClient";

// Import Styles
import { SerieWrapper, SerieContainer } from "../../styles/photoSeriesStyles";

// Import Firestore
import { fs } from "../../firebase";

const Clients = () => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    fs.collection("Clients")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        const tempAct = [];
        snapshot.forEach((doc) => {
          tempAct.push({ ...doc.data(), id: doc.id });
        });
        setClients(tempAct);
      });
  }, []);

  return (
    <SerieContainer>
      {clients.map((client) => (
        <SerieWrapper key={client.id}>
          <Link to={`clients/${client.id}`}>
            <img
              src={client.image ? client.image : []}
              alt={`${client.name}`}
            />
            <p>{client.name}</p>
          </Link>
          <DeleteClient name={client.id} client={client} />
        </SerieWrapper>
      ))}
      <hr className="hr0" />
      <NewClient />
    </SerieContainer>
  );
};

export default Clients;
