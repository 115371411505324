import React, { useState } from "react";

// Import Styles
import { SerieForm } from "../../styles/newSerieStyles";
import { Submit } from "../../styles/layout";

// Import Toastify
import { toast } from "react-toastify";

// import firestore
import { fs } from "../../firebase";
import firebase from "firebase";

const NewClient = () => {
  const [clientName, setClientName] = useState("");

  const onClientNameChange = (e) => {
    setClientName(e.target.value);
  };

  const notifyError = () => toast.error(" 🔥 IMPOSSIBLE DE CRéER CET éVENT");

  const notifySuccess = () => toast.success(" ✔️ éVENT CRéé AVEC SUCCèS");

  const onClientCreate = () => {
    if (!clientName) {
      return notifyError();
    }
    fs.collection("Clients").doc(clientName).set({
      name: clientName,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setClientName("");
    notifySuccess();
  };

  return (
    <SerieForm>
      <input value={clientName} onChange={onClientNameChange} type="text" />
      <Submit onClick={onClientCreate}>Ajouter un client</Submit>
    </SerieForm>
  );
};

export default NewClient;
